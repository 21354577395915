import { MsgBot } from "@@types/msgBot";
import ApiService from "./ApiService";

export const getBotMsgs = async (id) => {
  return ApiService.fetchData<Array<MsgBot>>({
    url: `/bot/${id}`,
    method: "GET",
  });
};
export const getFunctions = async () => {
  return ApiService.fetchData<Array<string>>({
    url: "/bot/functions",
    method: "GET",
  });
};
