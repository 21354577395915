import { FuseNavItemType } from "@fuse/core/FuseNavigation/types/FuseNavItemType";

import { Role } from "@@types/roles";

// i18next.addResourceBundle("en", "navigation", en);
// i18next.addResourceBundle("tr", "navigation", tr);
// i18next.addResourceBundle("ar", "navigation", ar);

/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */
const navigationConfig: FuseNavItemType[] = [
  {
    id: "apps.dashboard",
    title: "Dashboard",
    type: "item",
    icon: "dashboard",
    url: "/dashboard",
    translate: "nav.dashboard",
    auth: [Role.DASHBOARD],
  },
  {
    id: "apps.messenger",
    title: "Messenger",
    type: "item",
    icon: "chat",
    url: "/chat",
    translate: "nav.chat",
    auth: [Role.ALL_CONVERSATIONS, Role.ONLY_ASSIGNED_CONVERSATIONS],
  },
  {
    id: "apps.broadcast",
    title: "Broadcast",
    type: "collapse",
    icon: "broadcast",
    translate: "nav.broadcast",
    auth: [Role.BULK_MESSAGES],

    children: [
      {
        id: "apps.broadcastItem",
        title: "Broadcast",
        type: "item",
        url: "/broadcast",
        icon: "broadcastPage",

        translate: "nav.broadcast",
        end: true,
      },
      {
        id: "apps.contacts-groups",
        title: "Contacts groups",
        type: "item",
        url: "/contacts-groups",
        icon: "contactsGroups",

        translate: "nav.contactsGroups",
        end: true,
      },
    ],
  },

  {
    id: "apps.appointment",
    title: "Appointment",
    type: "item",
    icon: "appointment",
    url: "/appointment",
    translate: "nav.appointment",
    auth: [Role.APPOINTMENT],
  },
  {
    id: "apps.companyMessages",
    title: "Company Messages",
    type: "collapse",
    icon: "companyMessages",
    translate: "nav.companyMessages",
    auth: [Role.MESSAGES_COMPANY],
    children: [
      {
        id: "apps.companyMessages.facebookComments",
        title: "facebookComment",
        type: "collapse",

        translate: "nav.facebookComment",
        auth: [Role.MESSAGES_COMPANY],

        children: [
          {
            id: "apps.companyMessages.facebookComment",
            title: "Company Messages Facebook Comment",
            type: "item",
            url: "/companyMessages/facebook_comment/comment",

            translate: "nav.comment",
            end: true,
          },
        ],
      },
      {
        id: "apps.companyMessages.instagramComments",
        title: "instagramComments",
        type: "collapse",

        translate: "nav.instagramComment",
        auth: [Role.MESSAGES_COMPANY],

        children: [
          {
            id: "apps.companyMessages.instagramComment",
            title: "Company Messages Instagram Comment",
            type: "item",
            url: "/companyMessages/instagram_comment/comment",

            translate: "nav.comment",
            end: true,
          },
        ],
      },
      {
        id: "apps.companyMessages.whatsapp",
        title: "WhatsApp",
        type: "collapse",

        translate: "nav.whatsapp",
        auth: [Role.MESSAGES_COMPANY],

        children: [
          {
            id: "apps.companyMessages.text",
            title: "Company Messages Text",
            type: "item",
            url: "/companyMessages/whatsapp/text",

            translate: "nav.text",
            end: true,
          },
          {
            id: "apps.companyMessages.audio",
            title: "Company Messages Audio",
            type: "item",
            url: "/companyMessages/whatsapp/audio",

            translate: "nav.audio",
            end: true,
          },
          {
            id: "apps.companyMessages.contact",
            title: "Company Messages contact",
            type: "item",
            url: "/companyMessages/whatsapp/contact",

            translate: "nav.contact",
            end: true,
          },
          {
            id: "apps.companyMessages.image",
            title: "Company Messages image",
            type: "item",
            url: "/companyMessages/whatsapp/image",

            translate: "nav.image",
            end: true,
          },
          {
            id: "apps.companyMessages.video",
            title: "Company Messages video",
            type: "item",
            url: "/companyMessages/whatsapp/video",

            translate: "nav.video",
            end: true,
          },
          {
            id: "apps.companyMessages.document",
            title: "Company Messages document",
            type: "item",
            url: "/companyMessages/whatsapp/document",

            translate: "nav.document",
            end: true,
          },
          {
            id: "apps.companyMessages.location",
            title: "Company Messages Location",
            type: "item",
            url: "/companyMessages/whatsapp/location",

            translate: "nav.location",
            end: true,
          },
          {
            id: "apps.companyMessages.interactive",
            title: "Company Messages Interactive",
            type: "item",
            url: "/companyMessages/whatsapp/interactive",

            translate: "nav.interactive",
            end: true,
          },
          {
            id: "apps.companyMessages.template",
            title: "Company Messages Template",
            type: "item",
            url: "/companyMessages/whatsapp/template",

            translate: "nav.template",
            end: true,
          },
        ],
      },
      {
        id: "apps.companyMessages.messenger",
        title: "Messenger",
        type: "collapse",

        translate: "nav.messenger",
        auth: [Role.MESSAGES_COMPANY],

        children: [
          {
            id: "apps.companyMessages.messenger.text",
            title: "Company Messages Text",
            type: "item",
            url: "/companyMessages/messenger/text",

            translate: "nav.text",
            end: true,
          },
          {
            id: "apps.companyMessages.messenger.audio",
            title: "Company Messages Audio",
            type: "item",
            url: "/companyMessages/messenger/audio",

            translate: "nav.audio",
            end: true,
          },

          {
            id: "apps.companyMessages.messenger.image",
            title: "Company Messages image",
            type: "item",
            url: "/companyMessages/messenger/image",

            translate: "nav.image",
            end: true,
          },
          {
            id: "apps.companyMessages.messenger.video",
            title: "Company Messages video",
            type: "item",
            url: "/companyMessages/messenger/video",

            translate: "nav.video",
            end: true,
          },
          {
            id: "apps.companyMessages.messenger.document",
            title: "Company Messages document",
            type: "item",
            url: "/companyMessages/messenger/document",

            translate: "nav.document",
            end: true,
          },
          {
            id: "apps.companyMessages.messenger.quickReplies",
            title: "Company Messages Quick Replies",
            type: "item",
            url: "/companyMessages/messenger/quick-replies",

            translate: "nav.quickReplies",
            end: true,
          },

          {
            id: "apps.companyMessages.messenger.template",
            title: "Company Messages Template",
            type: "item",
            url: "/companyMessages/messenger/template",

            translate: "nav.template",
            end: true,
          },
        ],
      },
      {
        id: "apps.companyMessages.instagram",
        title: "Instagram",
        type: "collapse",

        translate: "nav.instagram",
        auth: [Role.MESSAGES_COMPANY],

        children: [
          {
            id: "apps.companyMessages.instagram.text",
            title: "Company Messages Text",
            type: "item",
            url: "/companyMessages/instagram/text",

            translate: "nav.text",
            end: true,
          },
          {
            id: "apps.companyMessages.instagram.audio",
            title: "Company Messages Audio",
            type: "item",
            url: "/companyMessages/instagram/audio",

            translate: "nav.audio",
            end: true,
          },

          {
            id: "apps.companyMessages.instagram.image",
            title: "Company Messages image",
            type: "item",
            url: "/companyMessages/instagram/image",

            translate: "nav.image",
            end: true,
          },
          {
            id: "apps.companyMessages.instagram.video",
            title: "Company Messages video",
            type: "item",
            url: "/companyMessages/instagram/video",

            translate: "nav.video",
            end: true,
          },
          {
            id: "apps.companyMessages.instagram.document",
            title: "Company Messages document",
            type: "item",
            url: "/companyMessages/instagram/document",

            translate: "nav.document",
            end: true,
          },
          {
            id: "apps.companyMessages.instagram.quickReplies",
            title: "Company Messages Quick Replies",
            type: "item",
            url: "/companyMessages/instagram/quick-replies",

            translate: "nav.quickReplies",
            end: true,
          },

          {
            id: "apps.companyMessages.instagram.template",
            title: "Company Messages Template",
            type: "item",
            url: "/companyMessages/instagram/template",

            translate: "nav.template",
            end: true,
          },
        ],
      },
    ],
  },

  {
    id: "apps.companyMessages",
    title: "Company Messages",
    type: "collapse",
    icon: "companyMessages",
    translate: "nav.companyMessages",
    auth: [Role.MESSAGES_COMPANY],

    children: [
      {
        id: "apps.companyMessages.text",
        title: "Company Messages Text",
        type: "item",
        url: "/companyMessages/text",

        translate: "nav.text",
        end: true,
      },
      {
        id: "apps.companyMessages.audio",
        title: "Company Messages Audio",
        type: "item",
        url: "/companyMessages/audio",

        translate: "nav.audio",
        end: true,
      },
      {
        id: "apps.companyMessages.contact",
        title: "Company Messages contact",
        type: "item",
        url: "/companyMessages/contact",

        translate: "nav.contact",
        end: true,
      },
      {
        id: "apps.companyMessages.image",
        title: "Company Messages image",
        type: "item",
        url: "/companyMessages/image",

        translate: "nav.image",
        end: true,
      },
      {
        id: "apps.companyMessages.video",
        title: "Company Messages video",
        type: "item",
        url: "/companyMessages/video",

        translate: "nav.video",
        end: true,
      },
      {
        id: "apps.companyMessages.document",
        title: "Company Messages document",
        type: "item",
        url: "/companyMessages/document",

        translate: "nav.document",
        end: true,
      },
      {
        id: "apps.companyMessages.location",
        title: "Company Messages Location",
        type: "item",
        url: "/companyMessages/location",

        translate: "nav.location",
        end: true,
      },
      {
        id: "apps.companyMessages.interactive",
        title: "Company Messages Interactive",
        type: "item",
        url: "/companyMessages/interactive",

        translate: "nav.interactive",
        end: true,
      },
      {
        id: "apps.companyMessages.template",
        title: "Company Messages Template",
        type: "item",
        url: "/companyMessages/template",

        translate: "nav.template",
        end: true,
      },
    ],
  },
  {
    id: "apps.messages",
    title: "Messages",
    type: "item",
    icon: "messages",
    url: "/messages",
    translate: "nav.messages",
    auth: [Role.MESSAGES_PAGE],
  },
  {
    id: "apps.contacts",
    title: "Contacts",
    type: "item",
    icon: "contacts",
    url: "/contacts",
    translate: "nav.contacts",
    auth: [Role.CONTACTS],
  },
  {
    id: "apps.consultations",
    title: "Consultations",
    type: "item",
    icon: "consultations",
    url: "/consultations",
    translate: "nav.consultations",
    auth: [Role.CONSULTATION_PAGE],
  },
  {
    id: "apps.team",
    title: "Team",
    type: "item",
    icon: "users",
    url: "/team",
    translate: "nav.teamManagement",
    auth: [Role.TEAM_MANAGEMENT],
  },

  {
    id: "apps.bot",
    title: "Bot",
    type: "item",
    icon: "bot",
    url: "/bots",
    exact: true,
    end: true,
    translate: "nav.bot",
    auth: [Role.BOT_PAGE],
  },
  {
    id: "apps.gpt",
    title: "Gpt",
    type: "item",
    icon: "gpt",
    url: "/gpt",
    translate: "nav.gpt",
    auth: [Role.GPT_PAGE],
  },
  {
    id: "apps.settings",
    title: "Settings",
    type: "item",
    icon: "settings",
    url: "/advanced-settings",
    translate: "nav.advancedSettings",
    auth: [Role.ADMIN],
  },
];

export default navigationConfig;
