import {
  activeChat,
  conversationAssigned,
  conversationUnAssigned,
  deleteChatTag,
  getChatsApi,
  getConsultationsApi,
  handleFinishedChat,
  handleReciveMsg,
  markConsultationAsReplied,
  refreshChatsApi,
  replaceConversations,
  updateChatTag,
  updateCommentReactions,
  updateConversationTypes,
  updateMsg,
  updatePost,
  updatePostReaction,
} from "app/store/slices/chat";
import { useEffect, useState } from "react";
import {
  addCompanyMsgs,
  getCompanyMessagesApi,
  updateCompanyMsgs,
  updateTemplate,
} from "app/store/slices/companyMessages";
import {
  addNewContact,
  getTags,
  handleAddNewTag,
  handleDeleteTag,
  handleUpdateTag,
  updateContact,
} from "@@app/store/slices/contactsSlice";
import {
  addAppointment,
  deleteAppointment,
  deleteLabel,
  newLabel,
  updateLabelData,
} from "app/store/slices/appointment";
import { getTeamList } from "app/store/slices/teamListSlice";
import {
  getConfig,
  getSettings,
  instagramId,
  messengerId,
  userPhone,
} from "@@app/store/slices/settings";
import {
  newBroadcastMsg,
  updateBroadcastMsg,
} from "app/store/slices/broadcast";
import { addMsgBot, deleteMsgBot, updateMsgBot } from "app/store/slices/bot";
import {
  createGptData,
  deleteGptData,
  updateGptData,
} from "app/store/slices/gpt";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import { Message } from "@@types/message";
import { socket } from "./webSocket";
import jwtAuthConfig from "@@app/auth/services/jwt/jwtAuthConfig";
import {
  getProfile,
  updateFacebookProfile,
  updateInstagramProfile,
  updateWhatsappProfile,
} from "@@app/store/slices/companyProfile";
import { useSnackbar } from "notistack";
import UserAvatar from "@@app/main/pages/messenger/UserAvatar";
import MsgTypeMapper from "@@app/main/pages/messenger/ui/messages/MsgTypeMapper";
import { CircularProgress, IconButton, Paper, Typography } from "@mui/material";
import { formatPhone } from "@fuse/utils/formatPhone";
import useDateFormat from "@fuse/hooks/useDateFormat";
import { CgClose } from "react-icons/cg";
import { store } from "@@app/store/store";
import { Tag } from "@@types/contacts";
import {
  getTeamChats,
  reciveMsg,
  setUserStatus,
  updateTeamMsg,
} from "@@app/store/slices/teamChat";
import { Role } from "@@types/roles";
import { userToken } from "@@app/store/slices/userSlice";
import { t } from "i18next";

const useWebSocket = () => {
  const [anotherConnection, setAnotherConnection] = useState(false);
  const user = useAppSelector((state) => state.user.user);
  const token = useAppSelector(userToken);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { dateFormat } = useDateFormat();
  const dispatch = useAppDispatch();

  const handleReceiveMsg = (msg: Message) => {
    dispatch(handleReciveMsg({ ...msg }));
    if (msg.showInConsultation) {
      // dispatch(handleReciveConsultationMsg({ ...msg }));
    }

    const companyPhone = userPhone(store.getState());
    const messenger = messengerId(store.getState());
    const instagram = instagramId(store.getState());

    if (msg.message_from === "me") return;
    if (msg.message_from === companyPhone) return;
    if (msg.message_from === messenger) return;
    if (msg.message_from === instagram) return;

    let title;
    if (msg?.product === "whatsapp" || msg?.product === "sms") {
      title = msg.conversation?.contact?.nickname
        ? msg.conversation?.contact?.nickname
        : formatPhone(msg.conversation.contact.waId);
    }
    if (msg?.product === "messenger") {
      title = msg.conversation?.contact?.name
        ? msg.conversation?.contact?.name
        : msg.conversation?.contact?.external_id;
    }
    if (msg?.product === "instagram") {
      title = msg.conversation?.contact?.name
        ? msg.conversation?.contact?.name
        : msg.conversation?.contact?.external_id;
    }
    if (msg?.product === "facebook_comment") {
      title = msg?.contacts?.name
        ? msg?.contacts?.name
        : msg?.contacts?.external_id;
    }
    if (msg?.product === "instagram_comment") {
      title = msg?.contacts?.name
        ? msg?.contacts?.name
        : msg?.contacts?.external_id;
    }
    enqueueSnackbar("", {
      key: msg.id,

      autoHideDuration: 5000,
      content: (
        <Paper className="flex items-center justify-center bg-white p-16 gap-10 rounded-md w-[330px]">
          <audio autoPlay src="notif.wav" className="hidden" />

          <UserAvatar
            name={
              msg?.contacts?.nickname ||
              msg?.contacts?.name ||
              msg?.contacts?.name
            }
            bg={msg?.contacts?.avatarColor}
            src={msg?.contacts?.profileAvatarUrl}
          />
          <div className="ps-16 w-full">
            <div className="flex gap-10 items-center">
              <div className="grid grid-cols-1 grow  ">
                <Typography className="truncate !text-lg">{title}</Typography>
              </div>
              <div className="flex items-center gap-5">
                {msg.conversation?.lastMessageDate && (
                  <Typography
                    className="whitespace-nowrap font-medium text-12"
                    color="text.secondary"
                  >
                    {dateFormat(msg.conversation.lastMessageDate)}
                  </Typography>
                )}
              </div>
            </div>
            <div className="flex items-center w-full gap-7 ">
              <div className="grow text-start !text-lg">
                <MsgTypeMapper msg={msg} />
              </div>
            </div>
          </div>
          <IconButton onClick={() => closeSnackbar()}>
            <CgClose />
          </IconButton>
        </Paper>
      ),
    });
  };
  const handleUpdateMsg = (msg: Message) => {
    dispatch(updateMsg(msg));
    if (msg.showInConsultation) {
      // dispatch(updateMsgConversation(msg));
    }
  };

  const handleUpdateTagData = (tag: Tag) => {
    dispatch(handleUpdateTag(tag));
    dispatch(updateChatTag(tag));
  };
  const handleDeleteTagData = (tag: Tag) => {
    dispatch(handleDeleteTag(tag));
    dispatch(deleteChatTag(tag));
  };

  const handleaAotherConnection = (data: boolean) => {
    setAnotherConnection(data);
  };

  const updateContactSocket = (data: any) => {
    dispatch(updateContact(data));
  };
  const createContact = (data: any) => {
    dispatch(addNewContact(data));
  };
  const createAppointment = (data: any) => {
    dispatch(addAppointment(data));
  };
  const updateAppointment = (data: any) => {
    // dispatch(updateAppointment(data))
  };
  const removeAppointment = (data: any) => {
    dispatch(deleteAppointment(data));
  };
  const handleConversationAssigned = (data: any) => {
    dispatch(conversationAssigned(data));
  };
  const handleConversationUnAssigned = (data: any) => {
    dispatch(conversationUnAssigned(data));
  };
  const finishedChat = (data: any) => {
    dispatch(handleFinishedChat(data));
  };
  const handleUpdateBulkMsg = (data: any) => {
    dispatch(updateBroadcastMsg(data));
  };
  const handleNewBulkMsg = (data: any) => {
    dispatch(newBroadcastMsg(data));
  };
  const handleError = (data: any) => {
    console.error(data);
  };
  const handleCreateTag = (data: any) => {
    dispatch(handleAddNewTag(data));
  };
  const handleCreateLabel = (data: any) => {
    dispatch(newLabel(data));
  };
  const handleUpdateLabel = (data: any) => {
    dispatch(updateLabelData(data));
  };
  const handleDeleteLabel = (data: any) => {
    dispatch(deleteLabel(data));
  };
  const handleUpdateCompanyMsg = (data: any) => {
    dispatch(updateCompanyMsgs(data));
  };
  const handleCreateCompanyMsg = (data: any) => {
    dispatch(addCompanyMsgs(data));
  };
  const handleCreateMsgBot = (data: any) => {
    dispatch(addMsgBot(data));
  };
  const handleUpdateMsgBot = (data: any) => {
    console.log("handleUpdateMsgBot");

    dispatch(updateMsgBot(data));
  };
  const handleDeleteMsgBot = (data: any) => {
    dispatch(deleteMsgBot(data));
  };
  const handleUpdateTemplate = (data: any) => {
    dispatch(updateTemplate(data));
  };

  const handleUpdateGpt = (data: any) => {
    dispatch(updateGptData(data));
  };
  const handleCreateGpt = (data: any) => {
    dispatch(createGptData(data));
  };
  const handleDeleteGpt = (data: any) => {
    dispatch(deleteGptData(data));
  };
  const handleIsOnlineUser = (data: any) => {
    dispatch(setUserStatus(data));
  };

  const handleReciveTeamMsg = (data: any) => {
    dispatch(reciveMsg(data));
  };
  const handleUpdateTeamMsg = (data: any) => {
    dispatch(updateTeamMsg(data));
  };
  const handleUpdateConversationTypes = (data: any) => {
    dispatch(updateConversationTypes(data));
  };
  const handleReplaceConversations = (data: any) => {
    dispatch(replaceConversations(data));
  };
  const handleMarkConsultationAsReplied = (data: any) => {
    dispatch(markConsultationAsReplied(data));
  };
  const handleUpdatePost = (data: any) => {
    dispatch(updatePost(data));
  };
  const handleupdateCommentReactions = (data: any) => {
    dispatch(updateCommentReactions(data));
  };
  const handleUpdatePostReaction = (data: any) => {
    dispatch(updatePostReaction(data));
  };
  const updateWhatsappProfileData = (data: any) => {
    dispatch(updateWhatsappProfile(data));
    dispatch(getConfig());
  };
  const updateInstagramProfileData = (data: any) => {
    dispatch(updateInstagramProfile(data));
    dispatch(getConfig());
  };
  const updateFacebookProfileData = (data: any) => {
    dispatch(updateFacebookProfile(data));
    dispatch(getConfig());
  };
  const refreshChats = (data: any) => {
    dispatch(refreshChatsApi({ unRead: false }));
  };
  const fetchConversations = (data: any) => {
    if (data) {
      enqueueSnackbar({
        persist: true,
        variant: "info",
        key: "fetchMessages",
        preventDuplicate: true,
        content: (
          <Paper className="flex items-center  bg-white p-16 gap-10 rounded-md w-[330px]">
            <CircularProgress size={20} />
            <Typography className="!text-lg">
              {t("settings.fetchMessages")}
            </Typography>
          </Paper>
        ),
      });
    } else {
      closeSnackbar("fetchMessages");
    }
  };

  const handleDisconnect = (data: any) => {
    localStorage.removeItem("whatsbot");
    localStorage.removeItem(jwtAuthConfig.tokenStorageKey);
    window.location.href = "/sign-in";
  };

  useEffect(() => {
    if (token && user) {
      socket.auth = { token };
      socket.connect();
      dispatch(getCompanyMessagesApi());
      dispatch(getTeamList());
      dispatch(getChatsApi({ unRead: false }));
      // dispatch(getUser())
      dispatch(getSettings());
      dispatch(getTags());
      dispatch(getProfile());
      dispatch(getTeamChats());
      if (
        user.roles.includes(Role.CONSULTATION_PAGE) ||
        user.roles.includes(Role.ADMIN)
      ) {
        dispatch(getConsultationsApi({}));
      }

      socket.on("addNewMessage", handleReceiveMsg);
      socket.on("updateMessage", handleUpdateMsg);
      socket.on("updateContact", updateContactSocket);
      socket.on("createContact", createContact);
      socket.on("createAppointment", createAppointment);
      socket.on("updateAppointment", updateAppointment);
      socket.on("removeAppointment", removeAppointment);
      socket.on("anotherConnection", handleaAotherConnection);
      socket.on("conversationAssigned", handleConversationAssigned);
      socket.on("updateBulkMsg", handleUpdateBulkMsg);
      socket.on("newBulkMsg", handleNewBulkMsg);
      socket.on("exception", handleError);
      socket.on("createTag", handleCreateTag);
      socket.on("updateTag", handleUpdateTagData);
      socket.on("deleteTag", handleDeleteTagData);
      socket.on("conversationUnAssigned", handleConversationUnAssigned);
      socket.on("conversationFinished", finishedChat);
      socket.on("createLabel", handleCreateLabel);
      socket.on("updateLabel", handleUpdateLabel);
      socket.on("deleteLabel", handleDeleteLabel);
      socket.on("createMaster", handleCreateCompanyMsg);
      socket.on("updateMaster", handleUpdateCompanyMsg);
      socket.on("createBot", handleCreateMsgBot);
      socket.on("updateBot", handleUpdateMsgBot);
      socket.on("deleteBot", handleDeleteMsgBot);
      socket.on("updateTemplate", handleUpdateTemplate);
      socket.on("createGpt", handleCreateGpt);
      socket.on("updateGpt", handleUpdateGpt);
      socket.on("deleteGpt", handleDeleteGpt);
      socket.on("isUserOnline", handleIsOnlineUser);
      socket.on("createTeamMessage", handleReciveTeamMsg);
      socket.on("updateTeamMessage", handleUpdateTeamMsg);
      socket.on("markConsultationAsReplied", handleMarkConsultationAsReplied);
      socket.on("updateConversationTypes", handleUpdateConversationTypes);
      socket.on("replaceConversation", handleReplaceConversations);
      socket.on("updatePost", handleUpdatePost);
      socket.on("updateCommentReaction", handleupdateCommentReactions);
      socket.on("updatePostReaction", handleUpdatePostReaction);
      socket.on("updateWhatsappProfile", updateWhatsappProfileData);
      socket.on("whatsappLogin", updateWhatsappProfileData);
      socket.on("instagramLogin", updateInstagramProfileData);
      socket.on("facebookLogin", updateFacebookProfileData);
      socket.on("refreshConversations", refreshChats);
      socket.on("fetchConversations", fetchConversations);
      // socket.on("disconnectUser", handleDisconnect);
    }

    return () => {
      socket.off("addNewMessage", handleReceiveMsg);
      socket.off("updateMessage", handleUpdateMsg);
      socket.off("updateContact", updateContactSocket);
      socket.off("createContact", createContact);
      socket.off("createAppointment", createAppointment);
      socket.off("updateAppointment", updateAppointment);
      socket.off("removeAppointment", removeAppointment);
      socket.off("anotherConnection", handleaAotherConnection);
      socket.off("conversationAssigned", handleConversationAssigned);
      socket.off("updateBulkMsg", handleUpdateBulkMsg);
      socket.off("newBulkMsg", handleNewBulkMsg);
      socket.off("exception", handleError);
      socket.off("createTag", handleCreateTag);
      socket.off("updateTag", handleUpdateTagData);
      socket.off("deleteTag", handleDeleteTagData);
      socket.off("conversationUnAssigned", handleConversationUnAssigned);
      socket.off("conversationFinished", finishedChat);
      socket.off("createLabel", handleCreateLabel);
      socket.off("updateLabel", handleUpdateLabel);
      socket.off("deleteLabel", handleDeleteLabel);
      socket.off("createMaster", handleCreateCompanyMsg);
      socket.off("updateMaster", handleUpdateCompanyMsg);
      socket.off("createBot", handleCreateMsgBot);
      socket.off("updateBot", handleUpdateMsgBot);
      socket.off("deleteBot", handleDeleteMsgBot);
      socket.off("updateTemplate", handleUpdateTemplate);
      socket.off("isUserOnline", handleIsOnlineUser);
      socket.off("createTeamMessage", handleReciveTeamMsg);
      socket.off("updateTeamMessage", handleUpdateTeamMsg);
      socket.off("markConsultationAsReplied", handleMarkConsultationAsReplied);
      socket.off("updateConversationTypes", handleUpdateConversationTypes);
      socket.off("replaceConversation", handleReplaceConversations);
      socket.off("updatePost", handleUpdatePost);
      socket.off("updateCommentReaction", handleupdateCommentReactions);
      socket.off("updatePostReaction", handleUpdatePostReaction);
      socket.off("updateWhatsappProfile", updateWhatsappProfileData);
      socket.off("instagramLogin", updateInstagramProfileData);
      socket.off("facebookLogin", updateFacebookProfileData);
      socket.off("whatsappLogin", updateWhatsappProfileData);
      socket.off("refreshConversations", refreshChats);
      socket.off("fetchConversations", fetchConversations);

      // socket.off("disconnectUser", handleDisconnect);

      socket.disconnect();
    };
  }, [token, user]);

  return anotherConnection;
};

export default useWebSocket;
