import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const BotSettingsApp = lazyWithRetries(() => import("./BotsApp"));

/**
 * The chat app config.
 */
const BotsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "bots",
      element: <BotSettingsApp />,
      auth: [Role.BOT_PAGE],
    },
  ],
};

export default BotsAppConfig;
