import React, { memo, useRef } from "react";
import PropTypes from "prop-types";
import { hasArabicCharacters } from "@fuse/utils/hasArabicCharacters";
import emojiRegex from "emoji-regex";
import CommentTag from "./CommentTag";

// Define styles for highlighted search terms
const styles = {
  highlight: {
    backgroundColor: "yellow", // Customize as needed
    padding: "0 2px",
    borderRadius: "2px",
  },
};

const EmojiComponent = memo(
  ({ children, size = 20 }: { children: any; size?: number }) => {
    const emojiString = React.Children.toArray(children).join("");

    // Function to convert emoji to the corresponding image URL
    function emojiToCodePoints(emoji) {
      const codePoints = [];
      for (const char of [...emoji]) {
        const codePoint = char.codePointAt(0).toString(16).padStart(4, "0");
        codePoints.push(codePoint);
      }
      return `https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${codePoints.join(
        "-"
      )}.png`;
    }

    const escapedSrc = emojiToCodePoints(emojiString).replace(/'/g, "\\'");
    return (
      <span
        className="emoji-img"
        style={
          {
            "--emoji-src": `url('${escapedSrc}')`,
          } as any
        }
      >
        {emojiString}
      </span>
    );
  }
);

// Helper function to wrap plain text with a span and set the dir attribute
const wrapWithDir = (text, getNextKey) => {
  if (!text) return null;
  const direction = hasArabicCharacters(text);
  return (
    <span dir={direction} key={getNextKey()}>
      {text}
    </span>
  );
};

const CustomTextRenderer = ({
  children = "",
  search = "",
  options = {},
}: {
  children: any;
  search?: string;
  options?: {
    disableBold?: boolean;
    disableItalic?: boolean;
    disableStrikethrough?: boolean;
    disableEmail?: boolean;
    disableURLs?: boolean;
    disablePhone?: boolean;
    disableBlockquote?: boolean;
    disableNumberedList?: boolean;
    disableBulletedList?: boolean;
    disableInlineCode?: boolean;
    disableBlockCode?: boolean;
    disableMention?: boolean;
    breakLines?: boolean;
  };
}) => {
  const text = React.Children.toArray(children).join("").trim();

  // Initialize a global key counter using useRef
  const keyRef = useRef(0);

  // Function to get the next unique key
  const getNextKey = () => keyRef.current++;

  // Merge default options with provided options
  const defaultOptions = {
    disableBold: false,
    disableItalic: false,
    disableStrikethrough: false,
    disableEmail: false,
    disableURLs: false,
    disablePhone: false,
    disableBlockquote: false,
    disableNumberedList: false,
    disableBulletedList: false,
    disableInlineCode: false,
    disableBlockCode: false,
    disableMention: false,
    breakLines: true,
  };

  const mergedOptions = { ...defaultOptions, ...options };

  // Function to parse code content for emojis
  const parseCodeContent = (codeText) => {
    // Define the emoji pattern without the 'g' flag
    const emojiPattern = new RegExp(
      emojiRegex().source,
      emojiRegex().flags.replace("g", "")
    );

    let elements = [];
    let remainingText = codeText;
    let match;
    let key = 0;

    while (remainingText.length > 0) {
      match = emojiPattern.exec(remainingText);
      if (match) {
        if (match.index > 0) {
          // Add text before the emoji
          const textBefore = remainingText.slice(0, match.index);
          elements.push(
            <span dir={hasArabicCharacters(textBefore)} key={key++}>
              {textBefore}
            </span>
          );
        }

        // Add the EmojiComponent
        elements.push(<EmojiComponent key={key++}>{match[0]}</EmojiComponent>);

        // Update remainingText
        remainingText = remainingText.slice(match.index + match[0].length);
      } else {
        // No more emojis; add the rest of the text
        elements.push(
          <span dir={hasArabicCharacters(remainingText)} key={key++}>
            {remainingText}
          </span>
        );
        break;
      }
    }

    return elements;
  };

  // Function to parse formatting patterns
  const parseFormatting = (inputText) => {
    // Define patterns for different formatting options
    let patterns = [
      {
        // hash #hash
        regex: /#(\S+)/,
        render: (match) => (
          <span className="text-blue-600 underline" key={getNextKey()}>
            #{parseFormatting(match[1])}
          </span>
        ),
      },
    ];
    // If a searchTerm is provided, add a pattern for it at the beginning to give it higher priority
    if (search && search.trim() !== "") {
      // Escape special regex characters in searchTerm
      const escapedSearchTerm = search.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
      const searchTermRegex = new RegExp(`(${escapedSearchTerm})`, "gi");
      patterns.push({
        // Search term: matches the exact search term, case-insensitive and global
        regex: searchTermRegex,
        render: (match) => (
          <span style={styles.highlight} key={getNextKey()}>
            {match[1]}
          </span>
        ),
      });
    }

    // Conditionally add patterns based on options
    if (!mergedOptions.disableBold) {
      patterns.push({
        // Bold text: matches text wrapped in asterisks, e.g., *text*
        regex: /\*(.*?)\*/,
        render: (match) => (
          <strong key={getNextKey()}>
            {parseFormatting(match[1])} {/* Recursive Parsing */}
          </strong>
        ),
      });
    }

    if (!mergedOptions.disableItalic) {
      patterns.push({
        // Italic text: matches text wrapped in underscores, e.g., _text_
        regex: /_(.*?)_/,
        render: (match) => (
          <em key={getNextKey()}>
            {parseFormatting(match[1])} {/* Recursive Parsing */}
          </em>
        ),
      });
    }

    if (!mergedOptions.disableStrikethrough) {
      patterns.push({
        // Strikethrough text: matches text wrapped in tildes, e.g., ~text~
        regex: /~(.*?)~/,
        render: (match) => (
          <del key={getNextKey()}>
            {parseFormatting(match[1])} {/* Recursive Parsing */}
          </del>
        ),
      });
    }
    if (!mergedOptions.disableMention) {
      patterns.push({
        // mention @[mention]
        regex: /@\[(.*?)\]/,
        render: (match) => (
          <CommentTag id={match[1]} key={getNextKey()} />
          // <span className="text-blue-600 underline" key={getNextKey()}>
          //   @{parseFormatting(match[1])} {/* Recursive Parsing */}
          // </span>
        ),
      });
    }

    if (!mergedOptions.disableEmail) {
      patterns.push({
        // Email addresses: matches standard email patterns
        regex: /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i,
        render: (match) => (
          <a
            className="!text-blue-600 !bg-transparent !underline"
            href={`mailto:${match[0]}`}
            key={getNextKey()}
          >
            {match[0]}
          </a>
        ),
      });
    }

    if (!mergedOptions.disableURLs) {
      patterns.push({
        // URLs: matches web addresses with or without protocol
        regex:
          /\b((?:https?:\/\/)?(?:www\.)?(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/\S*)?)/i,
        render: (match) => {
          const url = match[0].startsWith("http")
            ? match[0]
            : `https://${match[0]}`;
          return (
            <a
              href={url}
              className="!text-blue-600 !bg-transparent !underline"
              target="_blank"
              rel="noopener noreferrer"
              key={getNextKey()}
            >
              {match[0]}
            </a>
          );
        },
      });
    }

    if (!mergedOptions.disablePhone) {
      patterns.push({
        // Phone numbers: matches various phone number formats
        regex: /\+?\d{1,3}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}/,
        render: (match) => (
          <a
            className="!text-blue-600 !bg-transparent !underline"
            href={`tel:${match[0]}`}
            key={getNextKey()}
          >
            {match[0]}
          </a>
        ),
      });
    }

    // Always include emoji pattern
    patterns.push({
      // Emoji pattern without the 'g' flag
      regex: new RegExp(
        emojiRegex().source,
        emojiRegex().flags.replace("g", "")
      ),
      render: (match) => (
        <EmojiComponent key={getNextKey()}>{match[0]}</EmojiComponent>
      ),
    });

    let elements = [];
    let remainingText = inputText;

    while (remainingText.length > 0) {
      let earliestMatch = null;
      let earliestIndex = -1;
      let matchedPattern = null;

      // Find the earliest match among all patterns
      for (let pattern of patterns) {
        const match = pattern.regex.exec(remainingText);
        if (match) {
          if (earliestIndex === -1 || match.index < earliestIndex) {
            earliestMatch = match;
            earliestIndex = match.index;
            matchedPattern = pattern;
          }
        }
      }

      if (earliestMatch) {
        if (earliestIndex > 0) {
          // Add text before the match, wrapped with dir
          const textBefore = remainingText.slice(0, earliestIndex);
          elements.push(wrapWithDir(textBefore, getNextKey));
        }

        // Add the matched formatted element
        elements.push(matchedPattern.render(earliestMatch));

        // Update remainingText to process the rest
        remainingText = remainingText.slice(
          earliestIndex + earliestMatch[0].length
        );
      } else {
        // No more matches; add the rest of the text, wrapped with dir
        elements.push(wrapWithDir(remainingText, getNextKey));
        break;
      }
    }

    return elements;
  };

  // Function to parse lines for block-level elements
  const parseLines = (inputText) => {
    const lines = inputText.split("\n");
    const elements = [];

    let i = 0;

    while (i < lines.length) {
      const line = lines[i];
      const trimmedLine = line.trim();

      if (trimmedLine.startsWith(">") && !mergedOptions.disableBlockquote) {
        // Blockquote
        const blockquoteContent = trimmedLine.slice(1).trim();
        const direction = hasArabicCharacters(blockquoteContent);
        elements.push(
          <blockquote
            key={getNextKey()}
            dir={direction}
            className="border-s-4 ps-10 border-grey-500 rounded text-gray-700"
          >
            {parseInline(blockquoteContent)}
          </blockquote>
        );
        i++;
      } else if (
        /^\d+\.\s/.test(trimmedLine) &&
        !mergedOptions.disableNumberedList
      ) {
        // Numbered list
        const items = [];
        while (i < lines.length && /^\d+\.\s/.test(lines[i].trim())) {
          const lineContent = lines[i].trim().replace(/^\d+\.\s/, "");

          items.push(
            <li dir={hasArabicCharacters(lineContent)} key={getNextKey()}>
              {parseInline(lineContent)}
            </li>
          );
          i++;
        }

        elements.push(
          <ol
            dir={hasArabicCharacters(
              items?.[0].props?.children?.[0].props?.children
            )}
            key={getNextKey()}
            className="list-decimal ms-14 text-start"
          >
            {items}
          </ol>
        );
      } else if (
        /^[-*]\s/.test(trimmedLine) &&
        !mergedOptions.disableBulletedList
      ) {
        // Bulleted list
        const items = [];
        while (i < lines.length && /^[-*]\s/.test(lines[i].trim())) {
          const lineContent = lines[i].trim().replace(/^[-*]\s/, "");

          items.push(
            <li
              dir={hasArabicCharacters(lineContent)}
              key={getNextKey()}
              className="text-start"
            >
              {parseInline(lineContent)}
            </li>
          );
          i++;
        }

        elements.push(
          <ul
            dir={hasArabicCharacters(
              items?.[0].props?.children?.[0].props?.children
            )}
            key={getNextKey()}
            className="list-disc ms-14"
          >
            {items}
          </ul>
        );
      } else if (trimmedLine.length === 0) {
        if (mergedOptions.breakLines) {
          // Empty line
          elements.push(<br key={getNextKey()} />);
        } else {
          elements.push(<span key={getNextKey()}> </span>);
        }
        i++;
      } else {
        if (mergedOptions.breakLines) {
          // Regular paragraph
          const direction = hasArabicCharacters(trimmedLine);
          elements.push(
            <p key={getNextKey()} dir={direction}>
              {parseInline(trimmedLine)}
            </p>
          );
        } else {
          // Regular paragraph
          const direction = hasArabicCharacters(trimmedLine);
          elements.push(
            <span key={getNextKey()} dir={direction}>
              {parseInline(trimmedLine)}
            </span>
          );
        }

        i++;
      }
    }

    return elements;
  };

  // Function to parse inline elements and handle code
  const parseInline = (inputText) => {
    // Handle inline code first
    if (!mergedOptions.disableInlineCode) {
      const inlineCodeRegex = /`([^`]+)`/g;
      let elements = [];
      let lastIndex = 0;
      let match;

      while ((match = inlineCodeRegex.exec(inputText)) !== null) {
        if (match.index > lastIndex) {
          const textBefore = inputText.slice(lastIndex, match.index);
          elements = elements.concat(parseFormatting(textBefore));
        }
        // Add inline code with parsed content
        elements.push(
          <code
            className="bg-gray-300/50 p-2 h-fit inline-block"
            key={getNextKey()}
          >
            {parseCodeContent(match[1])}
          </code>
        );
        lastIndex = match.index + match[0].length;
      }

      if (lastIndex < inputText.length) {
        const textAfter = inputText.slice(lastIndex);
        elements = elements.concat(parseFormatting(textAfter));
      }

      return elements;
    } else {
      // If inline code is disabled, just parse formatting
      return parseFormatting(inputText);
    }
  };

  const parseText = (inputText) => {
    if (!mergedOptions.breakLines) {
      inputText = inputText.replaceAll("\n", " ");
    }
    // Handle block code first
    if (!mergedOptions.disableBlockCode) {
      const blockCodeRegex = /```([\s\S]*?)```/g;
      let parts = [];
      let lastIndex = 0;
      let match;

      while ((match = blockCodeRegex.exec(inputText)) !== null) {
        if (match.index > lastIndex) {
          // Process text before the code block
          const textBefore = inputText.slice(lastIndex, match.index);
          parts = parts.concat(parseLines(textBefore));
        }
        // Add code block with parsed content
        parts.push(
          <pre
            className="text-lg"
            key={getNextKey()}
            dir={hasArabicCharacters(match[1])}
          >
            <code>{parseCodeContent(match[1])}</code>
          </pre>
        );
        lastIndex = match.index + match[0].length;
      }

      if (lastIndex < inputText.length) {
        const textAfter = inputText.slice(lastIndex);
        parts = parts.concat(parseLines(textAfter));
      }

      return parts;
    } else {
      // If block code is disabled, just parse lines
      return parseLines(inputText);
    }
  };

  const content = parseText(text);

  return <>{content}</>;
};

// Define PropTypes for better type checking
// CustomTextRenderer.propTypes = {
//   children: PropTypes.node.isRequired,
//   search: PropTypes.string, // Added search propType
//   options: PropTypes.shape({
//     disableBold: PropTypes.bool,
//     disableItalic: PropTypes.bool,
//     disableStrikethrough: PropTypes.bool,
//     disableEmail: PropTypes.bool,
//     disableURLs: PropTypes.bool,
//     disablePhone: PropTypes.bool,
//     disableBlockquote: PropTypes.bool,
//     disableNumberedList: PropTypes.bool,
//     disableBulletedList: PropTypes.bool,
//     disableInlineCode: PropTypes.bool,
//     disableBlockCode: PropTypes.bool,
//   }),
// };

export default CustomTextRenderer;
