import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import rtlPlugin from "stylis-plugin-rtl";
import createCache, { Options } from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { selectCurrentLanguageDirection } from "app/store/i18nSlice";
import themeLayouts from "app/theme-layouts/themeLayouts";
import { selectMainTheme } from "@fuse/core/FuseSettings/fuseSettingsSlice";
import { useAppSelector } from "app/store/hooks";
import { useSelector } from "react-redux";
import withAppProviders from "./withAppProviders";
import AuthenticationProvider from "./auth/AuthenticationProvider";
import useWebSocket from "@@socket/useWebSocket";
import "animate.css";
import useConfig from "@fuse/hooks/useConfig";
import AnotherConnection from "./AnotherConnection";

// import axios from 'axios';
/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

const emotionCacheOptions = {
  rtl: {
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
  ltr: {
    key: "muiltr",
    stylisPlugins: [],
    insertionPoint: document.getElementById("emotion-insertion-point"),
  },
};

/**
 * The main App component.
 */
function App() {
  const anotherConnection = useWebSocket();
  useConfig();
  /**
   * The language direction from the Redux store.
   */
  const langDirection = useAppSelector(selectCurrentLanguageDirection);

  /**
   * The main theme from the Redux store.
   */
  const mainTheme = useSelector(selectMainTheme);

  if (anotherConnection) return <AnotherConnection />;
  return (
    // <MockAdapterProvider>
    <CacheProvider
      value={createCache(emotionCacheOptions[langDirection] as Options)}
    >
      <FuseTheme theme={mainTheme} root>
        <AuthenticationProvider>
          <FuseLayout layouts={themeLayouts} />
        </AuthenticationProvider>
      </FuseTheme>
    </CacheProvider>
    // </MockAdapterProvider>
  );
}

export default withAppProviders(App);
