import { Role } from "@@types/roles";
import { lazyWithRetries } from "@fuse/utils/lazyWithRetries";

const TextWhatsapp = lazyWithRetries(
  () => import("./whatsapp/text/TextMessages")
);
const ContactWhatsapp = lazyWithRetries(
  () => import("./whatsapp/contact/ContactMessages")
);
const ImageWhatsapp = lazyWithRetries(
  () => import("./whatsapp/image/ImageMessages")
);
const VideoWhatsapp = lazyWithRetries(
  () => import("./whatsapp/video/VideoMessages")
);
const DocumentWhatsapp = lazyWithRetries(
  () => import("./whatsapp/document/DocumentMessages")
);
const LocationWhatsapp = lazyWithRetries(
  () => import("./whatsapp/location/LoactionMessages")
);
const AudioWhatsapp = lazyWithRetries(
  () => import("./whatsapp/audio/AudioMessages")
);
const InteractiveWhatsapp = lazyWithRetries(
  () => import("./whatsapp/interactive/InteractiveMessages")
);
const TemplateWhatsapp = lazyWithRetries(
  () => import("./whatsapp/template/TemplateMessages")
);

const TextInstagram = lazyWithRetries(
  () => import("./instagram/text/TextMessages")
);
const ImageInstagram = lazyWithRetries(
  () => import("./instagram/image/ImageMessages")
);
const VideoInstagram = lazyWithRetries(
  () => import("./instagram/video/VideoMessages")
);
const DocumentInstagram = lazyWithRetries(
  () => import("./instagram/document/DocumentMessages")
);
const AudioInstagram = lazyWithRetries(
  () => import("./instagram/audio/AudioMessages")
);
const QuickRepliesInstagram = lazyWithRetries(
  () => import("./instagram/quick-replies/QuickReplies")
);
const TemplateInstagram = lazyWithRetries(
  () => import("./instagram/template/TemplateMessages")
);

const TextMessenger = lazyWithRetries(
  () => import("./messenger/text/TextMessages")
);
const ImageMessenger = lazyWithRetries(
  () => import("./messenger/image/ImageMessages")
);
const VideoMessenger = lazyWithRetries(
  () => import("./messenger/video/VideoMessages")
);
const DocumentMessenger = lazyWithRetries(
  () => import("./messenger/document/DocumentMessages")
);
const AudioMessenger = lazyWithRetries(
  () => import("./messenger/audio/AudioMessages")
);
const QuickReplies = lazyWithRetries(
  () => import("./messenger/quick-replies/QuickReplies")
);
const TemplateMessenger = lazyWithRetries(
  () => import("./messenger/template/TemplateMessages")
);
const FacebookComment = lazyWithRetries(
  () => import("./facebookComment/FacebookComment")
);
const InstagramComment = lazyWithRetries(
  () => import("./instagramComment/InstagramComment")
);
/**
 * The chat app config.
 */
const CompanyMessagesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "companyMessages/messenger/text",
      element: <TextMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/image",
      element: <ImageMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/video",
      element: <VideoMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/document",
      element: <DocumentMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/audio",
      element: <AudioMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/quick-replies",
      element: <QuickReplies />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/messenger/template",
      element: <TemplateMessenger />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram/text",
      element: <TextInstagram />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram/image",
      element: <ImageInstagram />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram/video",
      element: <VideoInstagram />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram/document",
      element: <DocumentInstagram />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram/audio",
      element: <AudioInstagram />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram/quick-replies",
      element: <QuickRepliesInstagram />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram/template",
      element: <TemplateInstagram />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/text",
      element: <TextWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/audio",
      element: <AudioWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },

    {
      path: "companyMessages/whatsapp/contact",
      element: <ContactWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/image",
      element: <ImageWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/video",
      element: <VideoWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/document",
      element: <DocumentWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/location",
      element: <LocationWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/interactive",
      element: <InteractiveWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/whatsapp/template",
      element: <TemplateWhatsapp />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/facebook_comment/comment",
      element: <FacebookComment />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/instagram_comment/comment",
      element: <InstagramComment />,
      auth: [Role.MESSAGES_COMPANY],
    },
    // {
    //   path: "companyMessages/FlowEditor",
    //   element: <FlowEditor />,
    //   auth: [Role.MESSAGES_COMPANY],
    // },
  ],
};

export default CompanyMessagesAppConfig;
