import { SettingsData } from "@src/@types/settings";
import ApiService from "./ApiService";
import { BotSettings } from "@@types/botSettings";

export const getBotSettingsApi = () => {
  return ApiService.fetchData<BotSettings[]>({
    url: `/bot-master`,
    method: "get",
  });
};
export const getBotApi = (id: string) => {
  return ApiService.fetchData<BotSettings>({
    url: `/bot-master/${id}`,
    method: "get",
  });
};
export const updateBotApi = (data: BotSettings) => {
  return ApiService.fetchData<BotSettings>({
    url: `/bot-master/${data.id}`,
    method: "put",
    data,
  });
};
export const createBotApi = (data: BotSettings) => {
  return ApiService.fetchData<BotSettings>({
    url: `/bot-master`,
    method: "post",
    data,
  });
};

export const updateBotSettingsApi = (data: SettingsData) => {
  return ApiService.fetchData<BotSettings>({
    url: "/bot-master",
    method: "post",
    data,
  });
};
