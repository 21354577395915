import apiService from "../apiService";
import { BotSettings } from "@@types/botSettings";

export const RtkQueryBotsService = apiService
  .enhanceEndpoints({
    addTagTypes: ["bots-master"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBotSettings: builder.query<BotSettings, { id: string }>({
        query: ({ id }) => {
          return {
            url: `/bot-master/${id}`,
          };
        },
        providesTags: ["bots-master"],

        keepUnusedDataFor: 10,
      }),
      updateBotSettings: builder.mutation({
        query: (data: BotSettings) => ({
          url: `/bot-master/${data.id}`,
          method: "PUT",
          data: data,
        }),

        invalidatesTags: ["bots-master"],
      }),
      createBotSettings: builder.mutation({
        query: (data: BotSettings) => ({
          url: `/bot-master`,
          method: "POST",
          data: data,
        }),

        invalidatesTags: ["bots-master"],
      }),
      deleteBotSettings: builder.mutation({
        query: ({ id }) => ({
          url: `/bot-master/${id}`,
          method: "DELETE",
        }),

        invalidatesTags: ["bots-master"],
      }),
    }),
  });

export const {
  useGetBotSettingsQuery,
  useUpdateBotSettingsMutation,
  useCreateBotSettingsMutation,
  useDeleteBotSettingsMutation,
} = RtkQueryBotsService;
