import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import {
  activeChat,
  addCompanyMessage,
  addMessage,
  addReactionMsg,
  receiveMsg,
  removeReaction,
  setActiveChat,
  setAssignTo,
  updateChatBotReplt,
  updateMsgAfterSend,
  zeroingUnReadMsgsChat,
} from "../slices/chat";

// import { handleNewMsgNotifi } from '@/utils/handleNotfi'
import { TeamMember } from "@@types/teamMember";
import { Message } from "@@types/message";
import { userPhone } from "../slices/settings";
import { socket } from "@@socket/webSocket";
import { selectUser } from "../slices/userSlice";
import { assignToChat, readChatMsg } from "@@services/ChatService";
import { companyProfile } from "../slices/companyProfile";

export const chatListener = createListenerMiddleware();

chatListener.startListening({
  matcher: isAnyOf(
    addMessage,
    setActiveChat,
    receiveMsg,
    addReactionMsg,
    removeReaction,
    addCompanyMessage,
    setAssignTo
  ),
  effect: async (action, { dispatch, getState }) => {
    const chatActiveted = activeChat(getState() as any);
    const companyPhone = userPhone(getState() as any);
    const profile = companyProfile(getState() as any);
    const user = selectUser(getState() as any);
    const payload = action.payload as any;

    switch (action.type) {
      case setActiveChat.type:
        if (
          chatActiveted?.unReadMsgs &&
          chatActiveted?.unReadMsgs[user!.username]
        ) {
          dispatch(zeroingUnReadMsgsChat({ ...payload, user }));
          readChatMsg(payload);
        }
        break;

      case receiveMsg.type:
        if ((payload as Message).sendBy) {
          dispatch(
            updateChatBotReplt({
              chat: payload!.conversation,
              value: false,
            })
          );
        }
        if (chatActiveted?.id === (payload as Message)?.conversation?.id) {
          dispatch(
            zeroingUnReadMsgsChat({
              ...(payload as Message).conversation,
              user: user!,
            })
          );

          if (
            (payload as Message).message_from !== companyPhone &&
            (payload as Message).message_from !== profile?.instagram?.id &&
            (payload as Message).message_from !== profile?.facebook?.id
          )
            readChatMsg(payload.conversation);
        } else {
          if (!payload.sendBy && payload.message_from !== companyPhone) {
            // handleNewMsgNotifi(
            //     payload?.conversation?.contact?.nickname
            //         ? payload.conversation.contact.nickname
            //         : payload?.conversation?.contact?.waId,
            // )
          }
          if (!payload.sendBy && payload.message_from === companyPhone) {
            dispatch(
              updateChatBotReplt({
                chat: payload!.conversation,
                value: true,
              })
            );
          }
        }
        break;
      case addMessage.type:
        socket.emit("sendMessage", payload, (val: any) => {
          dispatch(updateMsgAfterSend({ ...val, localId: payload.id }));
        });

        break;
      case removeReaction.type:
      case addReactionMsg.type:
        socket.emit("updateReaction", payload);
        break;
      // case addCompanyMessage.type:
      //     socket.emit('sendCompanyMsg', payload, (val: any) => {
      //         dispatch(
      //             updateMsgAfterSend({ ...val, localId: payload.id }),
      //         )
      //     })
      //     break
      case setAssignTo.type:
        assignToChat(
          chatActiveted!.id!,
          payload.map((user: TeamMember) => user.id)
        );

        break;
    }
  },
});
