import {
  getBotSettingsApi,
  updateBotSettingsApi,
} from "@@services/BotSettingsService";
import { BotSettings } from "@@types/botSettings";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import apiService from "../apiService";
import { RtkQueryBotsService } from "../services/bots";

export type BotSettingsSlice = {
  data?: BotSettings[];
};

const initialState: BotSettingsSlice = {
  data: [],
};

export const getBotSettings = createAsyncThunk(
  "botSettingsSlice/getBotSettings",
  async () => {
    const response = await getBotSettingsApi();
    return response.data;
  }
);

export const updateBotSettings = createAsyncThunk(
  "botSettingsSlice/updateBotSettings",
  async (data: BotSettings) => {
    const response = await updateBotSettingsApi(data);
    return response.data;
  }
);

export const botSettingsSlice = createSlice({
  name: "botSettingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBotSettings.fulfilled, (state, action) => {
        state.data = action.payload;
      })

      .addMatcher(
        RtkQueryBotsService.endpoints.deleteBotSettings.matchFulfilled,
        (state, action) => {
          state.data = state.data?.filter(
            (setting) => setting.id !== (action.payload as BotSettings).id
          );
        }
      );
  },
});

export const botSettingsData = (state: RootState) =>
  state.botSettingsSlice.data;
