import {
  getCompanyProfileApi,
  updateWhatsappProfileApi,
} from "@@services/CompanyProfile";
import {
  CompanyProfile,
  FacebookProfile,
  InstagramProfile,
  WhatsappProfile,
} from "@@types/companyProfile";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { facebookLogout, instagramLogout } from "./settings";

type CompanyProfileSlice = {
  data?: CompanyProfile;
};

export const getProfile = createAsyncThunk(
  "companyProfile/getProfile",
  async () => {
    const data = await getCompanyProfileApi();
    return data.data;
  }
);

const initialState: CompanyProfileSlice = {};

export const CompanyProfileSlice = createSlice({
  name: "companyProfile",
  initialState,
  reducers: {
    updateWhatsappProfile: (state, action: { payload: WhatsappProfile }) => {
      state.data.whatsapp = action.payload;
    },
    updateInstagramProfile: (state, action: { payload: InstagramProfile }) => {
      state.data.instagram = action.payload;
    },
    updateFacebookProfile: (state, action: { payload: FacebookProfile }) => {
      state.data.facebook = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(instagramLogout.fulfilled, (state, action) => {
        state.data.instagram = undefined;
      })
      .addCase(facebookLogout.fulfilled, (state, action) => {
        state.data.facebook = undefined;
      });
  },
  selectors: {
    companyProfile: (companyProfile) => companyProfile.data,
  },
});

export const {
  updateWhatsappProfile,
  updateInstagramProfile,
  updateFacebookProfile,
} = CompanyProfileSlice.actions;
export const { companyProfile } = CompanyProfileSlice.selectors;
