import { Contact } from "@@types/contacts";
import apiService from "../apiService";
import { ContactsFilter } from "../slices/contactsSlice";

export const RtkQueryContactsService = apiService
  .enhanceEndpoints({
    addTagTypes: ["Contacts"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getContacts: builder.query<
        { contacts: Contact[]; count: number },
        ContactsFilter & { product: string }
      >({
        query: ({ from, limit, page, sort, to, search, tags, product }) => {
          return {
            url: `/contacts/?limit=${limit ?? ""}&page=${page + 1}&dateFrom=${from ?? ""}&dateTo=${to ?? ""}&sort=${
              sort ?? ""
            }&search=${search ?? ""}&tags=${tags?.join(",") ?? ""}&product=${product ?? ""}`,
          };
        },
        providesTags: ["Contacts"],
      }),
      getByCommentId: builder.query<Contact, string>({
        query: (id) => {
          return {
            url: `/comments-tags/${id}`,
          };
        },
        providesTags: ["Contacts"],
        keepUnusedDataFor: 9999999,
      }),
      getByCommentSearch: builder.query<Contact[], string>({
        query: (search) => {
          return {
            url: `/comments-tags/?search=${search}`,
          };
        },
        providesTags: ["Contacts"],
        keepUnusedDataFor: 9999999,
      }),
      updateContact: builder.mutation({
        query: (data: Contact) => ({
          url: `/contacts/${data.id}`,
          method: "PUT",
          data: data,
        }),

        invalidatesTags: ["Contacts"],
      }),
      createContact: builder.mutation({
        query: (data: Contact) => ({
          url: `/contacts`,
          method: "Post",
          data: data,
        }),

        invalidatesTags: ["Contacts"],
      }),
    }),
  });

export const {
  useGetContactsQuery,
  useUpdateContactMutation,
  useCreateContactMutation,
  useGetByCommentIdQuery,
  useGetByCommentSearchQuery,
} = RtkQueryContactsService;
