import { CompanyProfile } from "@@types/companyProfile";
import ApiService from "./ApiService";

export const getCompanyProfileApi = async () => {
  return ApiService.fetchData<CompanyProfile>({
    url: "/company-profile",
    method: "GET",
  });
};
export const updateWhatsappProfileApi = async (data) => {
  return ApiService.fetchData<CompanyProfile>({
    url: "/company-profile",
    method: "POST",
    data,

    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
